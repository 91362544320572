import Popper, { PopperActions } from '../Popper';
import { Action } from '../../types/Action';
import { Globe02Icon } from '@hugeicons/react';
import { useTranslation } from 'react-i18next';
import { getAppConfigForID, getAppFront } from '../../types/AppConfig';
import apps from '../../apps';

const LocalePop = () => {
  const { i18n } = useTranslation();
  const appConfig = getAppConfigForID(apps, getAppFront());

  const changeLanguage = (lng: string) => {
    if (i18n.language !== lng) {
      localStorage.setItem(`${appConfig?.id}-i18n`, lng);
      i18n.changeLanguage(lng).then(() => {
        window.location.reload();
      });
    }
  };

  return Object.keys(appConfig?.locales || {}).length > 1 ? (
    <Popper
      id="languageselection"
      icon={Globe02Icon}
      classNames="mr-4"
      titleClasses="circular"
    >
      <PopperActions
        actions={Object.keys(appConfig?.locales!).map(
          (lKey) =>
            new Action({
              actionEvent: () => changeLanguage(lKey),
              label: appConfig?.locales![lKey].label,
              classnames: `popper-action ${
                i18n.language === lKey ? '!bg-teal-100' : ''
              }`,
            })
        )}
      />
    </Popper>
  ) : null;
};

export default LocalePop;
