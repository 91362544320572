import { appURI } from '../config';
import { AppHeaders, Header } from '../types/Header';
import { LogDoc } from '../types/Log';
import { nestedObject } from '../types/Object';
import { removeUndefinedProps } from '../utils/ObjectUtil';
import AuthFetchWrapper from './AuthFetchWrapper';

export enum MongoProcedure {
    FIND = 'find',
    FINDONE = 'findOne',
    FINDONEANDUPDATE = 'findOneAndUpdate',
    UPDATE = 'update',
    UPDATEONE = 'updateOne',
    INSERT = 'insert',
    INSERTONE = 'insertOne',
    DELETE = 'delete',
    DELETEONE = 'deleteOne',
    AGGREGATE = 'aggregate',
    COUNT = 'count',
}

export enum MongoManipulator {
    NONE = 'none',
    HASH = 'hash',
    OBJECTID = 'objectid',
}

type MongoQuery = nestedObject;
type MongoProjection = nestedObject;
type MongoHint = string;
type MongoSort = Record<string, 1 | -1>;
type MongoUnset = Record<string, 1>;

export type ManipulateBody = Record<string, MongoManipulator>;

interface MongoCallOptions {
    procedure?: MongoProcedure;
    collection: MongoCollection;
    query?: MongoQuery;
    body?: any;
    manipulateBody?: ManipulateBody;
    replace?: boolean;
    unset?: MongoUnset;
    upsert?: boolean;
    fixedUpdate?: any;
    returnDoc?: boolean;
    log?: LogDoc;
    collectionLog?: nestedObject;
    project?: MongoProjection;
    hint?: MongoHint;
    sort?: MongoSort;
    skip?: number;
    limit?: number;
    pager?: boolean;
    headers?: Header;
    insecure?: boolean;
    callback?: Function;
    pipe?: Array<object>;
}

type MongoParams = {
    method: string;
    headers?: Header;
    body?: string;
};

// Predefined Views and Collections
const MongoViews = [
    'activeStaff',
    'activeMaterials',
    'activeEmployers',
    'activeVehicles',
    'activeVehicleCategories',
    'activeVehicleModels',
    'activeSuppliers',
    'activeCustomergroups',
    'activeCustomers',
    'activeUsers',
    'activeVatCodes',
    'activeAssigners',
    'activePartners',
    'activeShips',
    'activeAdvancePayments',
    'staffPhoneBook',
    'addressPhoneBook',
    'peoplePhoneBook',
    'customerPhoneBook',
    'employerPhoneBook',
    'partnerPhoneBook',
    'ordersQMS',
    'ordersTopCustomers',
    'ordersTopEmployers',
    'ordersTopConnect',
    'complaintsQMS',
    'lastOnroadLocations',
    'lastDispatchHourRegistration',
    'activeOneUsers',
    'ordersGorseleOneWaiting',
    'ordersGorseleOneNoPrice',
    'orderChecksCount',
    'activeConnectUsers'
] as const;

const MongoCollections = [
    'counters',
    'staff',
    'ships',
    'partners',
    'manuals',
    'employers',
    'advancepayments',
    'customers',
    'customergroups',
    'vehiclemodels',
    'vehicles',
    'vehiclecategories',
    'materials',
    'suppliers',
    'tracks',
    'users',
    'orders',
    'leave',
    'leaverequests',
    'fines',
    'complaints',
    'customersettings',
    'assigners',
    'tomtomlocations',
    'dispatchhours',
    'oneusers',
    'standbysettings',
    'exxonusers',
    'exxonorders',
    'people',
    'ordertypes',
    'budgets',
    'addresses',
    'countries',
    'cities',
    'depots',
    'forceddepots',
    'trajects',
    'ordersearch'
] as const;

type MongoCollection = (typeof MongoCollections)[number] | (typeof MongoViews)[number];

interface MongoCallMethods {
    execute: () => Promise<Response>;
}

// MongoCall Class
export class MongoCall implements MongoCallMethods {
    procedure: MongoProcedure;
    collection: MongoCollection;
    query?: MongoQuery;
    body?: any;
    manipulateBody?: ManipulateBody;
    replace: boolean;
    unset?: MongoUnset;
    upsert?: boolean;
    fixedUpdate?: nestedObject;
    returnDoc?: boolean;
    log?: LogDoc;
    collectionLog?: nestedObject;
    project?: MongoProjection;
    hint?: MongoHint;
    sort?: MongoSort;
    skip?: number;
    limit?: number;
    pager?: boolean;
    headers?: Header;
    insecure: boolean;
    callback?: Function;
    pipe?: Array<object>;

    constructor(options: MongoCallOptions) {
        if (options.fixedUpdate && !options.body && !options.query) {
            throw new Error('fixedUpdate needs body or query in construct options');
        }
        this.procedure = options.procedure || MongoProcedure.FIND;
        this.collection = options.collection;
        this.query = options.query;
        this.body = options.body;
        this.manipulateBody = options.manipulateBody;
        this.replace = options.replace || false;
        this.unset = options.unset;
        this.upsert = options.upsert;
        this.fixedUpdate = options.fixedUpdate;
        this.returnDoc = options.returnDoc;
        this.log = options.log;
        this.collectionLog = options.collectionLog;
        this.project = options.project;
        this.hint = options.hint;
        this.sort = options.sort;
        this.skip = options.skip;
        this.limit = options.limit;
        this.pager = options.pager;
        this.headers = options.headers;
        this.insecure = options.insecure || false;
        this.callback = options.callback;
        this.pipe = options.pipe;
    }

    private constructRequestBody(): nestedObject {
        if (this.body || this.fixedUpdate) {
            return removeUndefinedProps({
                doc: this.body,
                query: this.query,
                manipulate: this.manipulateBody,
                replace: this.replace,
                upsert: this.upsert,
                log: this.log,
                collectionLog: this.collectionLog,
                unset: this.unset,
                fixedUpdate: this.fixedUpdate,
                returnDoc: this.returnDoc
            });
        }

        return removeUndefinedProps({
            query: this.query,
            manipulate: this.manipulateBody,
            project: this.project,
            hint: this.hint,
            sort: this.sort,
            skip: this.skip,
            limit: this.limit,
            pager: this.pager,
            pipe: this.pipe,
        });
    }

    private constructHeaders(): Header | undefined {
        if (this.insecure) return undefined;
        return this.headers || AppHeaders();
    }

    execute = async (): Promise<Response> => {
        const headers = this.constructHeaders();
        if (!headers) {
            console.error('Headers are missing for MongoCall');
            return new Response();
        }

        const url = `${appURI}protected/mongoproc/${this.procedure}/collection/${this.collection}`;
        const params: MongoParams = {
            method: 'POST',
            headers,
            body: JSON.stringify(this.constructRequestBody()),
        };

        try {
            return await AuthFetchWrapper(url, params);
        } catch (error) {
            console.error('Error executing MongoCall:', error);
            throw error;
        }
    };
}