/* eslint-disable react-hooks/exhaustive-deps */
import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

export enum PopupSize {
  auto = 'auto',
  full = 'full',
}

type PopupSettings = {
  size: PopupSize;
};

type ShowPopupFunction = (
  content: ReactNode,
  size?: PopupSize | undefined
) => void;

interface PopupContextType {
  popupOpen: boolean;
  popupContent: ReactNode;
  popupSettings: PopupSettings;
  showPop: ShowPopupFunction;
  hidePop: Function;
}

interface PopupProviderProps {
  children: any;
}

const PopupContext = createContext<PopupContextType>({} as PopupContextType);

export const PopupProvider: FC<PopupProviderProps> = ({ children }) => {
  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const [popupContent, setPopupContent] = useState<ReactNode | null>(null);
  const [popupSettings, setPopupSettings] = useState<PopupSettings>({
    size: PopupSize.auto,
  });

  const showPop = (content: ReactNode, size?: PopupSize): void => {
    setPopupOpen(true);
    setPopupSettings({ size: size || PopupSize.auto });
    setPopupContent(content || null);
  };

  const hidePop = () => {
    setPopupOpen(false);
    setPopupContent(null);
  };

  const value = useMemo(
    () => ({
      popupOpen,
      popupContent,
      popupSettings,
      showPop,
      hidePop,
    }),
    [popupOpen, popupContent, popupSettings]
  );
  return (
    <PopupContext.Provider value={value}>{children}</PopupContext.Provider>
  );
};

export const usePopup = () => {
  return useContext(PopupContext);
};
