const handleUnauthorizedResponse = (response: Response): void => {
  if (response.status === 401) {
    window.location.href = '/signout';
  }
};

const AuthFetchWrapper = async (url: string, params: object): Promise<Response> => {
  try {
    const response = await fetch(url, params);

    // Check for unauthorized response and handle it
    handleUnauthorizedResponse(response);

    return response;
  } catch (error) {
    console.error('Error in AuthFetchWrapper:', error);
    throw error; // Re-throw the error to allow the caller to handle it
  }
};

export default AuthFetchWrapper;