class DocType {
    type: string;
    link: string;
    constructor(type: string, link: string) {
        if (!type || !link) {
            throw new Error('type and link are required for every DocType');
        }
        this.type = type;
        this.link = link;
    }
}

export const DocTypes = {
    user: new DocType('user', '/users'),
    order: new DocType('order', '/orders'),
    manual: new DocType('manual', '/manuals'),
    supplier: new DocType('supplier', '/suppliers'),
    ship: new DocType('ship', '/ships'),
    material: new DocType('material', '/garage/materials'),
    vehicleCategory: new DocType('vehicleCategory', '/garage/vehiclecategories'),
    vehicleModel: new DocType('vehicleModel', '/garage/vehiclemodels'),
    vehicle: new DocType('vehicle', '/garage/vehicles'),
    employer: new DocType('employer', '/staff/employers'),
    partner: new DocType('partner', '/staff/partners'),
    staffmember: new DocType('staff', '/staff/staff'),
    advancePayment: new DocType('staff', '/staff/advancepayments'),
    customergroup: new DocType('customergroup', '/customers/customergroups'),
    customer: new DocType('customer', '/customers/customers'),
    assigner: new DocType('assigner', '/customers/assigners'),
    stafftimes: new DocType('dispatchhours', '/stafftimes/registrations'),
    gorseleoneuser: new DocType('gorseleoneuser', '/gorseleone/users'),
    loodswezenstandby: new DocType('loodswezenstandby', '/loodswezen/standby'),
    exxonUser: new DocType('exxonuser', '/exxon/users'),
    address: new DocType('address', '/addresses'),
    person: new DocType('person', '/people'),
    budget: new DocType('budget', '/budgets')
};

export enum LogOp {
    insert = 'insert',
    update = 'update',
    delete = 'delete',
};

export class LogDoc {
    logop: LogOp;
    text: string;
    doctype: DocType;
    documents?: Array<string> | undefined;
    constructor(logop: LogOp, text: string, doctype: DocType, documents?: Array<string> | undefined) {
        if (!logop) {
            throw new Error('logop is required');
        }
        if (!text?.length) {
            throw new Error('text is required with a minimum length of 3 characters');
        }
        if (!doctype) {
            throw new Error('every logdoc should specify the doctype');
        }
        this.logop = logop;
        this.text = text;
        this.doctype = doctype;
        this.documents = documents;
    }
}
