import { Evaluation, LogicalEvaluation } from "../utils/FormUtil";
import { IconType } from "./Icon";

export type ActionList = Array<Action>;

export enum ActionType {
    new = 'new',
    edit = 'edit',
    delete = 'delete',
    filter = 'filter',
    search = 'search',
    print = 'print',
    browse = 'browse',
    custom = 'custom',
}

interface IActionOptions {
    type?: ActionType;
    actionEvent?: Function;
    classnames?: string;
    label?: string;
    icon?: IconType;
    disabled?: Evaluation | LogicalEvaluation | boolean;
    visible?: Evaluation | LogicalEvaluation | boolean;
}

export class Action {
    type: ActionType;
    actionEvent?: Function;
    classnames?: string;
    label?: string;
    icon?: IconType;
    disabled?: Evaluation | LogicalEvaluation | boolean;
    visible?: Evaluation | LogicalEvaluation | boolean;
    constructor(options: IActionOptions) {
        this.type = options.type || ActionType.custom;
        this.actionEvent = options.actionEvent;
        this.classnames = options.classnames;
        this.label = options.label;
        this.icon = options.icon;
        this.disabled = options.disabled;
        this.visible = options.visible;
    }
}