import { IconType } from '../types/Icon';
import IconButton from './IconButton';

interface AppHeaderIconProps {
  icon: IconType;
  iconClasses?: string;
  text?: string;
  textClasses?: string;
  [key: string]: any;
}

const AppHeaderIcon = ({
  icon,
  iconClasses,
  text,
  textClasses,
  className,
  ...rest
}: AppHeaderIconProps) => {
  return (
    <div
      className={`flex flex-row items-center h-[36px] rounded-full border-[1px] border-menu-400 text-white text-sm px-2 transition-all scale-100 hover:scale-110 ${className} [&.circular]:w-[36px] [&.circular]:px-0 [&.circular]:justify-center`}
      {...rest}
    >
      <IconButton icon={icon} className={iconClasses} />
      {text ? (
        <span className={`ml-2 text-xs ${textClasses}`}>{text}</span>
      ) : null}
    </div>
  );
};

export default AppHeaderIcon;
