import { ShoppingBasket01Icon } from '@hugeicons/react';
import { useConnectBasket } from '../../hooks/useConnectBasket';
import AppHeaderIcon from '../AppHeaderIcon';
import { addToast } from '../../utils/ToastUtil';
import { useNavigate } from 'react-router-dom';

const ConnectBasketLink = () => {
  const { basket } = useConnectBasket();
  const navigate = useNavigate();

  const openBasket = () => {
    if (!basket?.length) return addToast('Winkelmand is leeg');
    navigate('/basket');
  };

  return (
    <div onClick={openBasket}>
      <AppHeaderIcon
        icon={ShoppingBasket01Icon}
        text={String(basket?.length || '')}
        textClasses="bg-green-300 w-4 h-4 rounded-full text-black flex justify-center items-center"
        className={`mr-4 hover:cursor-pointer ${
          !basket?.length ? 'circular' : ''
        }`}
      />
    </div>
  );
};

export default ConnectBasketLink;
