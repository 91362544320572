/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useMemo, useState, FC } from 'react';

interface SearchContextType {
  searchVisible: boolean;
  searchIsVisible: Function;
  searchIsHidden: Function;
}

interface SearchProviderProps {
  children: any;
}

const SearchContext = createContext<SearchContextType>({} as SearchContextType);

export const SearchProvider: FC<SearchProviderProps> = ({ children }) => {
  const [searchVisible, setSearchVisible] = useState<boolean>(false);

  const searchIsVisible = () => {
    setSearchVisible(true);
  };

  const searchIsHidden = () => {
    setSearchVisible(false);
  };

  const value = useMemo(
    () => ({
      searchVisible,
      searchIsVisible,
      searchIsHidden,
    }),
    [searchVisible]
  );
  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};

export const useSearch = () => {
  return useContext(SearchContext);
};
