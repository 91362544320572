import { nestedObject } from "../types/Object";
import { searchSafe } from "./TextUtil";

export const getNestedPropsFromString = (string: string, obj: nestedObject, flat: boolean = false, toString: boolean = false): any => {
  let value: any | undefined = undefined;
  let level: number = 0;
  if (
    string &&
    string.length &&
    obj &&
    Object.getOwnPropertyNames(obj).length
  ) {
    string.split('.').forEach((dataEl: string, i: number) => {
      if (i === level) {
        if (i === 0) {
          if (obj[dataEl] !== undefined) {
            value = obj[dataEl];
            level++;
          }
        } else {
          if (value !== undefined && value[dataEl] !== undefined) {
            value = value[dataEl];
            level++;
          }
          else {
            value = undefined;
          }
        }
      }
    });
    if (value === undefined && string.indexOf('.') > 0) {
      if (obj[string] !== undefined) {
        value = obj[string];
      }
    }
  }
  if (
    flat &&
    typeof value !== 'string' &&
    typeof value !== 'number' &&
    typeof value !== 'boolean'
  ) {
    value = undefined;
  }
  if (toString) {
    return String(value || '');
  }
  return value;
};

export type sortType = {
  [key: string]: 1 | -1
}

export const sortArrayOfObjects = (array: Array<any>, sort: sortType): Array<any> => {
  if (
    array &&
    array.length &&
    sort &&
    Object.getOwnPropertyNames(sort).length
  ) {
    var sortProps = Object.getOwnPropertyNames(sort);
    let sortFunc = (a: any, b: any) => {
      let res: any = 0;
      sortProps.forEach((s: string) => {
        var v1 = a ? getNestedPropsFromString(s, a, false) : 0;
        var v2 = b ? getNestedPropsFromString(s, b, false) : 0;
        let isString = false;
        if (typeof v1 === 'string' || typeof v2 === 'string') {
          isString = true;
          v1 = searchSafe(String(v1 || ''), true).toLowerCase();
          v2 = searchSafe(String(v2 || ''), true).toLowerCase();
        }
        if (sort[s] === 1) {
          res = res || (isString ? v1.localeCompare(v2.toString()) : v1 - v2);
        } else {
          res = res || (isString ? v2.toString().localeCompare(v1) : v2 - v1);
        }
      });
      return Number(res);
    };
    array.sort(sortFunc);
  }
  return array;
};

type filterObject = {
  [key: string]: any
}


export const filterArrayOfObjects = (array: Array<any>, filter: filterObject): Array<any> => {
  if (!array?.length) return [];
  let filteredItems = array;
  let filteredProps = Object.keys(filter);
  for (let f = 0; f < filteredProps.length; f++) {
    const realProp = filteredProps[f];
    const value = filter[realProp];
    filteredItems = filteredItems.filter((item) => {
      const nestedVal = getNestedPropsFromString(realProp, item);
      return nestedVal !== null && nestedVal !== undefined
        ? new RegExp(
          (value !== null && value !== undefined ? value : '').toString(),
          'i'
        ).test(
          (nestedVal !== null && nestedVal !== undefined
            ? nestedVal
            : ''
          ).toString()
        )
        : false;
    });
  }
  return filteredItems;
};

export const convertArrayToObject = (array: Array<any>, key: string, prop: string) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: prop ? getNestedPropsFromString(prop, item) : item,
    };
  }, initialValue);
};

export const removeUndefinedProps = (obj: nestedObject, noLength: boolean = false): nestedObject => {
  if (!obj || !Object.getOwnPropertyNames(obj).length) return {};
  let strippedObj: nestedObject = {};
  Object.getOwnPropertyNames(obj).forEach((prop) => {
    if (obj[prop] !== null && obj[prop] !== undefined) {
      if (!noLength || obj[prop]?.toString().length > 0) {
        strippedObj[prop] = obj[prop];
      }
    }
  });
  return strippedObj;
};

export const getObjectFromArrayByPropValue = (array: Array<any>, prop: string, value: any): any => {
  if (!array?.length) return null;
  for (let a = 0; a < array.length; a++) {
    if (getNestedPropsFromString(prop, array[a]) === value) return array[a];
  }
  return null;
};

export const destructureByProps = (obj: nestedObject, destructure: string[]): nestedObject => {
  if (!obj || !destructure?.length) return {};
  let rObj: nestedObject = {};
  destructure.forEach(key => {
    rObj[key] = getNestedPropsFromString(key, obj);
  })
  return rObj;
}

export const getObjectDifferences = (obj1: nestedObject, obj2: nestedObject): string[] => {
  if (!obj1 && !obj2) return [];
  let diff: string[] = [];
  const obj1Keys = Object.keys(obj1);
  let obj2Keys = Object.keys(obj2);
  for (const key of obj1Keys) {
    if (!obj2Keys.includes(key)) {
      diff.push(key);
    } else {
      if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
        diff.push(`${key}-value`);
      }
      obj2Keys.splice(obj2Keys.indexOf(key), 1);
    }
  }
  return diff.concat(obj2Keys);
};