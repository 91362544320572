/* eslint-disable react-hooks/exhaustive-deps */
import { FC, createContext, useContext, useMemo, useState } from 'react';
import { appURI } from '../config';

interface RecentItemContextType {
  recentItems: Array<any> | undefined;
  recentFetching: boolean;
  refreshRecentItems: Function;
}

interface RecentItemProviderProps {
  children: any;
}

const RecentItemContext = createContext<RecentItemContextType>(
  {} as RecentItemContextType
);

export const RecentItemProvider: FC<RecentItemProviderProps> = ({
  children,
}) => {
  const [recentItems, setRecentItems] = useState<Array<any> | undefined>();
  const [recentFetching, setRecentFetching] = useState<boolean>(false);
  // call this function when you want the sidebar to open/close

  const refreshRecentItems = async () => {
    setRecentFetching(true);
    const res = await fetch(`${appURI}recentitems`);
    setRecentFetching(false);
    const data = await res.json();
    if (data?.items) {
      setRecentItems(data.items);
    }
  };

  const value = useMemo(
    () => ({
      recentItems,
      recentFetching,
      refreshRecentItems,
    }),
    [recentItems, recentFetching]
  );
  return (
    <RecentItemContext.Provider value={value}>
      {children}
    </RecentItemContext.Provider>
  );
};

export const useRecentItems = () => {
  return useContext(RecentItemContext);
};
