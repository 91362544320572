import Popper, { PopperActions } from '../Popper';
import { Action } from '../../types/Action';
import { Door01Icon } from '@hugeicons/react';

const DoorPop = () => {
  const onDoorOpen = () => {
    console.log('open the door');
  };

  const onGateOpen = () => {
    console.log('open the gate');
  };

  return (
    <Popper
      id="doorlocker"
      icon={Door01Icon}
      classNames="mr-4"
      titleClasses="circular"
    >
      <PopperActions
        actions={[
          new Action({
            actionEvent: onDoorOpen,
            label: 'Voordeur openen',
            classnames: 'popper-action',
          }),
          new Action({
            actionEvent: onGateOpen,
            label: 'Poort openen',
            classnames: 'popper-action',
          }),
        ]}
      />
    </Popper>
  );
};

export default DoorPop;
