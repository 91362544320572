import { JWT } from "../config";

export type Header = {
    'Content-Type': string,
    Authorization: string,
    [key: string]: string
}

export const AppHeaders = (): Header | undefined => {
    const jwt = localStorage.getItem(JWT);
    if (!jwt) return undefined;
    return {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + JSON.parse(jwt),
    };
};