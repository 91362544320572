import { getAppFront } from '../types/AppConfig';
import { Header } from '../types/Header';
import { nestedObject } from '../types/Object';
import { removeUndefinedProps } from '../utils/ObjectUtil';
import AuthFetchWrapper from './AuthFetchWrapper';
import { MongoCall } from './MongoProc';

type CacheItem = {
    cachedAt: number;
    value: any;
};

// Cache Management Functions

const getCacheKey = (key?: string) => {
    return `gorsele-${getAppFront()}-cache-${key || ''}`;
}

export const clearCache = (): void => {
    Object.keys(localStorage).forEach((key) => {
        if (key.startsWith(getCacheKey())) {
            localStorage.removeItem(key);
        }
    });
};

export const removeFromCache = (id: string): void => {
    localStorage.removeItem(getCacheKey(id));
};

const saveToCache = (id: string, value: any): void => {
    const cacheItem: CacheItem = { cachedAt: Date.now(), value };
    localStorage.setItem(getCacheKey(id), JSON.stringify(cacheItem));
};

const retrieveFromCache = (id: string): CacheItem | null => {
    const cachedData = localStorage.getItem(getCacheKey(id));
    return cachedData ? (JSON.parse(cachedData) as CacheItem) : null;
};

// Helper Interfaces and Types

interface ApiCallStackItemOptions {
    callStackProp: string;
    apiCall: any;
}

export class ApiCallStackItem {
    callStackProp: string;
    apiCall: ApiCall;

    constructor({ callStackProp, apiCall }: ApiCallStackItemOptions) {
        if (!callStackProp || !apiCall) {
            throw new Error('callStackProp and apiCall are required for ApiCallStackItem');
        }
        this.callStackProp = callStackProp;
        this.apiCall = apiCall;
    }
}

export type PreProcessCallStackResult = (result: nestedObject) => nestedObject;

type CallStackResultItem = Record<string, any>;

export type PreProcessApiCallResultReturn = {
    success: 1 | 0;
    result?: any;
    numDocs?: number;
    error?: string | Error;
};

export type PreProcessApiCallResult = (result: any) => PreProcessApiCallResultReturn;

export type ApiExecutionResult = {
    result?: any;
    time?: number;
    error?: string | Error;
    pagerTotal?: number;
};

type TransformResultReturn = Array<nestedObject> | nestedObject | null | undefined;

type ApiCallResult = Array<nestedObject> | nestedObject | null | undefined;

export type TransformResult = (result: ApiCallResult) => TransformResultReturn;

// ApiCallStack Class

interface ApiCallStackOptions {
    callStackItems: ApiCallStackItem[];
    preProcessResult?: PreProcessCallStackResult;
}

export class ApiCallStack {
    callStackItems: ApiCallStackItem[];
    preProcessResult?: PreProcessCallStackResult;

    constructor({ callStackItems, preProcessResult }: ApiCallStackOptions) {
        if (!callStackItems?.length) {
            throw new Error('callStackItems is required');
        }
        this.callStackItems = callStackItems;
        this.preProcessResult = preProcessResult;
    }

    execute = async (pager?: ApiCallPager): Promise<ApiExecutionResult> => {
        const startTime = Date.now();
        let results: CallStackResultItem = {};

        for (let item of this.callStackItems) {
            const stackResult = await item.apiCall.execute(pager);
            results[item.callStackProp] = stackResult.result;
        }

        if (this.preProcessResult) {
            results = this.preProcessResult(results);
        }

        return {
            result: results?.numDocs !== undefined || results?.result ? results.result : results,
            pagerTotal: results.numDocs,
            time: Date.now() - startTime,
        };
    };
}

// ApiCall Class

interface ApiCallOptions {
    action?: Function;
    actionProps?: nestedObject;
    mongoCall?: MongoCall;
    uri?: string;
    method?: string;
    query?: nestedObject;
    headers?: Header;
    noHeaders?: boolean;
    cacheID?: string;
    cacheMaxAge?: number;
    cacheDelete?: boolean;
    preProcessResult?: PreProcessApiCallResult;
    transformResult?: TransformResult;
}

export class ApiCall {
    private action?: Function;
    private actionProps?: nestedObject;
    private mongoCall?: MongoCall;
    private uri?: string;
    private method: string;
    private query?: nestedObject;
    private headers?: Header;
    private noHeaders?: boolean;
    private cacheID?: string;
    private cacheMaxAge: number;
    private cacheDelete: boolean;
    public preProcessResult?: PreProcessApiCallResult;
    public transformResult?: TransformResult;

    constructor(options: ApiCallOptions) {
        this.action = options.action;
        this.actionProps = options.actionProps;
        this.mongoCall = options.mongoCall;
        this.uri = options.uri;
        this.method = options.method || 'GET';
        this.query = options.query;
        this.headers = options.headers;
        this.noHeaders = options.noHeaders;
        this.cacheID = options.cacheID;
        this.cacheMaxAge = options.cacheMaxAge || 12 * 60 * 60 * 1000;
        this.cacheDelete = options.cacheDelete || false;
        this.preProcessResult = options.preProcessResult;
        this.transformResult = options.transformResult;
    }

    private processCachedResult(cachedResult: CacheItem): ApiExecutionResult {
        if (this.cacheDelete || (this.cacheMaxAge && cachedResult.cachedAt < Date.now() - this.cacheMaxAge)) {
            removeFromCache(this.cacheID!);
            return { error: 'Cache expired' };
        }

        const result = this.transformResult
            ? this.transformResult(cachedResult.value)
            : cachedResult.value;

        return { result, time: 1 };
    }

    private async processApiResult(apiResult: Response, start: number): Promise<ApiExecutionResult> {
        try {
            let data = await apiResult.json();
            if (this.preProcessResult) {
                data = this.preProcessResult(data);
            }
            if (data?.success) {
                if (this.cacheID) saveToCache(this.cacheID, data.result);

                const result = this.transformResult
                    ? this.transformResult(data.result)
                    : data.result;
                return { result, pagerTotal: data.numDocs, time: Date.now() - start };
            }

            return { error: data?.error || 'API call failed' };
        } catch (error) {
            console.error('API Processing Error:', error);
            return { error: 'API call processing error' };
        }
    }

    execute = async (pager?: ApiCallPager): Promise<ApiExecutionResult> => {
        const startTime = Date.now();

        if (this.cacheID) {
            const cachedResult = retrieveFromCache(this.cacheID);
            if (cachedResult) return this.processCachedResult(cachedResult);
        }

        let apiResult: any;

        const q = this.query || {};
        const skipLimit = { skip: pager?.currentPage !== undefined && pager?.maxRows !== undefined ? pager.currentPage * pager.maxRows : undefined, limit: pager?.maxRows, pager: pager ? true : false }

        if (this.action) {
            const actionProps = { ...this.actionProps, ...(pager ? skipLimit : {}) };
            apiResult = await this.action!(actionProps);
        } else if (this.mongoCall) {
            apiResult = await new MongoCall({
                ...this.mongoCall,
                ...(pager ? skipLimit : {})
            }).execute();
        } else if (this.uri) {
            const params: RequestInit = {
                method: this.method,
                headers: this.noHeaders ? undefined : this.headers || { 'Content-Type': 'application/json' },
                body: this.method === 'POST' || this.method === 'PUT' ? JSON.stringify({ ...q, ...(pager ? skipLimit : {}) }) : undefined,
            };
            apiResult = await AuthFetchWrapper(this.uri, removeUndefinedProps(params));
        } else {
            return { error: 'No action, mongoCall, or URI provided' };
        }

        return this.processApiResult(apiResult, startTime);
    };
}

// ApiCallPager Class

export class ApiCallPager {
    currentPage: number;
    maxRows: number;

    constructor(currentPage: number, maxRows: number) {
        if (currentPage === undefined || maxRows === undefined) {
            throw new Error('currentPage and maxRows are required for ApiCallPager');
        }

        this.currentPage = currentPage;
        this.maxRows = maxRows;
    }
}