/* eslint-disable react-hooks/exhaustive-deps */
import {
  FC,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { OneOfficeUser } from '../types/OneOfficeUser';
import { StaffTimeRegistration } from '../types/StaffTimeRegistration';
import { useAuth } from './useAuth';
import { getStaffTimeRegistrationLatest } from '../viewActions/StaffTimeActions';
import { APPID, getAppFront } from '../types/AppConfig';

type SetStatusFunction = (registration: StaffTimeRegistration) => void;

interface StaffTimeContextType {
  lastRegistration?: StaffTimeRegistration;
  user?: OneOfficeUser;
  startWorking: Function;
  stopWorking: Function;
  loadStatus: Function;
  setStatus: SetStatusFunction;
}

interface StaffTimeProviderProps {
  children: any;
}

const StaffTimeContext = createContext<StaffTimeContextType>(
  {} as StaffTimeContextType
);

export const StaffTimeProvider: FC<StaffTimeProviderProps> = ({ children }) => {
  const [lastRegistration, setLastRegistration] = useState<
    StaffTimeRegistration | undefined
  >();
  const { user } = useAuth();

  // call this function when you want to start working
  const startWorking = () => {};

  // call this function when you want to stop working
  const stopWorking = () => {};

  // call this function to load last working status
  const loadStatus = async () => {
    if (user) {
      const time = (
        await getStaffTimeRegistrationLatest(user as OneOfficeUser).execute()
      ).result;
      setLastRegistration(time);
    }
  };

  const setStatus = (registration: StaffTimeRegistration) => {
    setLastRegistration(registration);
  };

  useEffect(() => {
    if (
      getAppFront() === APPID.oneoffice &&
      user?.staff?.timeRegistration &&
      !lastRegistration
    ) {
      loadStatus();
    }
  }, [user, lastRegistration]);

  const value = useMemo(
    () => ({
      lastRegistration,
      startWorking,
      stopWorking,
      loadStatus,
      setStatus,
    }),
    [lastRegistration]
  );
  return (
    <StaffTimeContext.Provider value={value}>
      {children}
    </StaffTimeContext.Provider>
  );
};

export const useStaffTime = () => {
  return useContext(StaffTimeContext);
};
