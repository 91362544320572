import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './hooks/useAuth';
import { GorseleTranslationProvider } from './hooks/useGorseleTranslation';
import { setDefaultOptions } from 'date-fns/setDefaultOptions';
import { nlBE, enUS, fr } from 'date-fns/locale';
import AppLoader from './components/AppLoader';
import { getAppConfigForID, getAppFront } from './types/AppConfig';
import { Helmet } from 'react-helmet';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import apps from './apps';
import defaultLocale from './locales/nl.json';
import { addAppLocales } from './utils/LocaleUtil';
import Router from './Router';

const dateFNSLocales: { [key: string]: any } = {
  nl: nlBE,
  en: enUS,
  fr: fr,
};

const appConfig = getAppConfigForID(apps, getAppFront());
const appLocale = localStorage.getItem(`${appConfig?.id}-i18n`);
setDefaultOptions({ locale: appLocale ? dateFNSLocales[appLocale] : nlBE });

i18n.use(initReactI18next).init({
  resources: addAppLocales(appConfig?.locales) || {
    nl: {
      translation: defaultLocale,
    },
  },
  lng: appLocale || 'nl',
  parseMissingKeyHandler: () => {
    return `keymissing`;
  },
});

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <GorseleTranslationProvider>
          <Suspense fallback={<AppLoader />}>
            <div>
              {appConfig?.id ? (
                <Helmet
                  htmlAttributes={{ lang: appLocale || 'nl', dir: i18n.dir() }}
                >
                  <title>{appConfig?.name}</title>
                  <meta
                    name="description"
                    content={`${appConfig?.name} application`}
                  />
                  <link
                    rel="manifest"
                    href={`/manifest_${appConfig?.id}.json`}
                    data-react-helmet="true"
                  />
                </Helmet>
              ) : null}
              <Router />
            </div>
          </Suspense>
        </GorseleTranslationProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
