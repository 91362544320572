export const getRandomInt = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min) + min);
};

export const round = (number: number, decimals?: number | undefined) => {

  if ((decimals || 0) > 0) {
    const decimalMultiplier = Math.pow(10, decimals!) + 0.0;
    return Math.round(number * (decimalMultiplier * 10) / 10) / decimalMultiplier;
  } else {
    return Math.round(number);
  }
};

export const fixedDecimals = (number: number, decimals: number | undefined = 2) => {
  return round(number, decimals).toFixed(decimals);
};

export const numberToRadius = (number: number): number => {
  return number * Math.PI / 180;
}

export const numberToDegree = (number: number): number => {
  return number * 180 / Math.PI;
}