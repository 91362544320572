import Loader from './Loader';

interface AppLoaderProps {
  text?: string;
  [key: string]: any;
}

const AppLoader = ({ text, ...rest }: AppLoaderProps) => {
  return (
    <div className="w-screen h-[100dvh] fixed top-0 left-0 z-[9999] bg-black bg-opacity-60 flex justify-center items-center">
      <div className="bg-white p-6 rounded-md flex flex-col items-center">
        {text ? <div className="mb-3">{text}</div> : null}
        <Loader />
      </div>
    </div>
  );
};

export default AppLoader;
