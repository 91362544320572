import { FC } from "react";
import { IconType } from "./Icon";
import { AppRole } from "./AppRole";

export interface AppRouteOptions {
    path: string | string[];
    label?: string;
    element?: FC;
    nav?: boolean;
    icon?: IconType;
    role?: AppRole;
    index?: boolean;
    parent?: string;
    pathForDetail?: boolean;
}

export class AppRoute {
    path: string | string[];
    label?: string;
    element?: FC;
    nav?: boolean;
    icon?: IconType;
    role?: AppRole;
    index?: boolean;
    parent?: string;
    pathForDetail?: boolean;

    constructor(options: AppRouteOptions) {
        if (!options?.path?.length) {
            throw new Error('path is required for AppRoute');
        }
        this.path = options.path;
        this.label = options.label;
        this.element = options.element;
        this.nav = options.nav;
        this.icon = options.icon;
        this.role = options.role;
        this.index = options.index;
        this.parent = options.parent;
        this.pathForDetail = options.pathForDetail;
    }
}