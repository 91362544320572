/* eslint-disable react-hooks/exhaustive-deps */
import { FC, ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './hooks/useAuth';
import { AppRole, Role } from './types/AppRole';

interface RouteRoleCheckProps {
  children: ReactElement;
  role?: AppRole | undefined;
  urlPrefix?: string;
}

export const RouteRoleCheck: FC<RouteRoleCheckProps> = ({
  children,
  role,
  urlPrefix,
}): ReactElement => {
  const { user } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (role && user && !role.hasRole(new Role(user.role || ''))) {
      navigate(`${urlPrefix || ''}/`);
    }
  }, [role, user]);
  return children;
};
