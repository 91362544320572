type ToastType = 'error' | 'success' | 'info';

export const addToast = (message: string, type: ToastType = 'error') => {
    let toastHolder = document.getElementById('apptoaster');
    if (!document.body) {
        throw new Error('body missing');
    }
    if (!toastHolder) {
        let toastPositioner = document.createElement('div');
        toastPositioner.id = 'toastpositioner';
        toastPositioner.classList.add('apptoastpositioner')
        toastPositioner.classList.add('middle')
        toastHolder = document.createElement('div');
        toastHolder.id = 'apptoaster';
        toastHolder.classList.add('apptoastholder');
        toastPositioner.appendChild(toastHolder);
        document.body.appendChild(toastPositioner);
    }
    let toast = document.createElement('div');
    toast.classList.add('apptoast');
    toast.classList.add('in');
    toast.classList.add(type);
    toast.innerHTML = `<p>${message}</p>`;
    toastHolder.appendChild(toast);
    setTimeout(() => {
        toast.classList.add('out');
        setTimeout(() => {
            toastHolder!.removeChild(toast);
        }, 150);
    }, 4000);
}