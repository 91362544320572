/* eslint-disable react-hooks/exhaustive-deps */
import { useSideBar } from '../hooks/useSideBar';
import { useRecentItems } from '../hooks/useRecentItems';
import { useSearch } from '../hooks/useSearch';
import { useState } from 'react';
import StaffTimePop from './Header/StaffTimePop';
import DoorPop from './Header/DoorPop';
import StaffStartStopTimePop from './Header/StaffStartStopTimePop';
import AppSpecific from './AppSpecific';
import { APPID } from '../types/AppConfig';
import UserPop from './Header/UserPop';
import { SidebarLeftIcon } from '@hugeicons/react';
import LocalePop from './Header/LocalePop';
import ConnectBasketLink from './Header/ConnectBasketLink';

const AppHeader = () => {
  const { toggleSidebar } = useSideBar();
  const { refreshRecentItems } = useRecentItems();
  const { searchVisible } = useSearch();
  const [createVisible, setCreateVisible] = useState(false);
  const [recentVisible, setRecentVisible] = useState(false);

  const showRecentItems = () => {
    refreshRecentItems();
    setRecentVisible(true);
  };

  return (
    <div className="h-[60px] min-h-[60px] w-full flex flex-row items-center justify-between pl-2 pr-2 border-b-[1px] border-b-slate-600 header-bg">
      {!searchVisible ? (
        <div className="flex flex-row">
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md text-white hover:scale-110 transition-all focus:outline-none"
            onClick={() => toggleSidebar()}
          >
            <SidebarLeftIcon className="text-white w-6 h-6" />
          </button>

          <AppSpecific appid={APPID.oneoffice}>
            <div className="ml-4">
              <StaffTimePop />
            </div>
          </AppSpecific>

          {createVisible || recentVisible ? (
            <div
              className="w-[100vw] h-[100dvh] fixed top-0 left-0 bg-black opacity-50 z-[990]"
              onClick={() => {
                setCreateVisible(false);
                setRecentVisible(false);
              }}
            />
          ) : null}
        </div>
      ) : null}
      <AppSpecific appid={APPID.oneoffice}>
        <div className="hidden lg:flex">searchbar</div>
      </AppSpecific>
      {!searchVisible ? (
        <div className="flex flex-row justify-end items-center">
          <AppSpecific appid={APPID.connect}>
            <ConnectBasketLink />
          </AppSpecific>
          <AppSpecific appid={APPID.oneoffice}>
            <DoorPop />
          </AppSpecific>
          <AppSpecific appid={APPID.oneoffice}>
            <StaffStartStopTimePop />
          </AppSpecific>
          <LocalePop />
          <UserPop />
        </div>
      ) : null}
    </div>
  );
};

export default AppHeader;
