import { ObjectId } from './MongoDB';
import { OneOfficeUser } from './OneOfficeUser';
import { GorseleOneUser } from './GorseleOneUser';
import { ExxonUser } from './ExxonUser';
import { ConnectUser } from './ConnectUser';

export type AppUserRef = {
    _id: ObjectId,
    fullName: string,
}

export type AppUser = {
    [key: string]: any;
    _id?: ObjectId;
    firstName: string;
    lastName: string;
    fullName?: string;
    role?: string;
} & (OneOfficeUser | GorseleOneUser | ExxonUser | ConnectUser);

export const getInitials = (user: AppUser): string | undefined => {
    if (!user) return undefined;
    return `${user?.firstName?.trim().toUpperCase().charAt(0) || ''}${user?.lastName?.trim().toUpperCase().charAt(0) || ''
        }`;
};
