import { AppRoles } from '../../AppRoles';
import { useAuth } from '../../hooks/useAuth';
import { Role } from '../../types/AppRole';
import Popper, { PopperActions } from '../Popper';
import { useState } from 'react';
import { StaffTimeRegistration } from '../../types/StaffTimeRegistration';
import {
  getStaffTimeRegistrationLatest,
  storeStaffTime,
} from '../../viewActions/StaffTimeActions';
import { Action } from '../../types/Action';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import { parseOneOfficeUser } from '../../types/OneOfficeUser';
import { Building05Icon, StopWatchIcon, Home05Icon } from '@hugeicons/react';
import { addToast } from '../../utils/ToastUtil';

const StaffTimePop = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [dispatchLastRegistrations, setDispatchLastRegistrations] = useState<
    Array<StaffTimeRegistration> | undefined
  >();

  const loadPopTimeOptions = async () => {
    setLoading(true);
    const lastRegistrations = (await getStaffTimeRegistrationLatest().execute())
      .result;
    setLoading(false);
    setDispatchLastRegistrations(lastRegistrations);
  };

  const toggleStaffTime = async (registration: StaffTimeRegistration) => {
    if (
      (
        await Swal.fire({
          title: `Tijd ${registration.type ? 'starten' : 'stoppen'}`,
          text: `Ben je zeker dat je de tijd van ${
            registration.staff.alias
          } wil ${registration.type ? 'starten' : 'stoppen'}?`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Ja',
          cancelButtonText: 'Annuleren',
        })
      ).isConfirmed
    ) {
      if (await storeStaffTime({ staff: registration.staff })) {
        addToast('Werktijd opgeslagen', 'success');
      } else {
        addToast('Probleem bij opslaan werktijd');
      }
    }
  };

  return user &&
    AppRoles.Management.hasRole(
      new Role(parseOneOfficeUser(user)?.role || '')
    ) ? (
    <Popper
      id="stafftime"
      icon={StopWatchIcon}
      classNames="hidden lg:block"
      titleClasses="circular"
      onPopClick={(open: boolean) => {
        if (open) {
          loadPopTimeOptions();
        }
      }}
    >
      <PopperActions
        loading={loading}
        actions={
          !loading && dispatchLastRegistrations?.length
            ? dispatchLastRegistrations.map(
                (registration: StaffTimeRegistration) => {
                  return new Action({
                    actionEvent: () => toggleStaffTime(registration),
                    label: `${registration.staff.alias} - ${
                      registration.type === 0 ? 'Gestart' : 'Gestopt'
                    }: ${format(registration.time, 'dd/MM HH:mm')}. ${
                      registration.type === 0 ? 'Stop' : 'Start'
                    } werktijd`,
                    classnames: `popper-action !justify-start ${
                      registration.type === 0 ? 'search' : 'delete'
                    }`,
                    icon: registration.office ? Building05Icon : Home05Icon,
                  });
                }
              )
            : []
        }
      />
    </Popper>
  ) : null;
};

export default StaffTimePop;
