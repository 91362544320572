import './Sidebar.css';
import { Link, useLocation } from 'react-router-dom';
import { useSideBar } from '../hooks/useSideBar';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import { Role } from '../types/AppRole';
import { AppRoute } from '../types/AppRoute';
import { getAppConfigForID, getAppFront } from '../types/AppConfig';
import apps from '../apps';
import { ArrowRight01Icon } from '@hugeicons/react';
import { useGorseleTranslation } from '../hooks/useGorseleTranslation';

interface MenuForRoutesProps {
  routes: Array<AppRoute>;
  parent?: string;
  subRoute?: boolean;
  [key: string]: any;
}

interface SpanElementProps {
  children: ReactNode;
  [key: string]: any;
}

const Sidebar = () => {
  const { sideBarOpen, sideBarWidth, toggleSidebar } = useSideBar();
  const location = useLocation();
  const [activePath, setActivePath] = useState<string>(
    location?.pathname || '/'
  );
  const { user } = useAuth();
  const appConfig = getAppConfigForID(apps, getAppFront());
  const { tk } = useGorseleTranslation();

  useEffect(() => {
    if (location?.pathname && location?.pathname !== activePath) {
      setActivePath(location.pathname);
    }
  }, [location?.pathname, activePath]);

  const hasSubItems = (path: string) => {
    if (!appConfig?.routes?.length) return false;
    for (let r = 0; r < appConfig?.routes.length; r++) {
      const route = appConfig?.routes[r];
      if (route.parent === path) {
        return true;
      }
    }
    return false;
  };

  const listSubItems = (route: AppRoute) => {
    if (!appConfig?.routes?.length) return [];
    let subItems: Array<AppRoute> = [];
    appConfig?.routes.forEach((subRoute) => {
      if (subRoute.parent === route.path) {
        if (route.role) {
          subItems.push({ ...subRoute, role: route.role });
        } else {
          subItems.push(subRoute);
        }
      }
    });
    return subItems.length ? subItems : [];
  };

  const isPathOpen = (path: string) => {
    return activePath.indexOf(path) === 0;
  };

  const SpanElement: FC<SpanElementProps> = ({ children, ...rest }) => {
    return <span {...rest}>{children}</span>;
  };

  const MenuForRoutes: FC<MenuForRoutesProps> = ({
    routes,
    parent,
    subRoute,
    ...rest
  }) => {
    return (
      <ul {...rest} className={subRoute ? 'submenu' : ''}>
        {routes.map((item, i) => {
          const Icon = item.icon;
          const NavElement = item.element ? Link : SpanElement;
          const path = Array.isArray(item.path) ? item.path[0] : item.path;
          const subs = hasSubItems(path);
          const allowRole =
            item.role && user
              ? item.role.hasRole(new Role(user?.role || '*'))
              : true;
          return (!item.parent || item.parent === parent) &&
            allowRole &&
            item.nav ? (
            <li
              key={i}
              className={subs ? (isPathOpen(path) ? 'open' : '') : ''}
            >
              <NavElement
                to={path}
                className={`!hidden lg:!flex link ${
                  activePath === path ||
                  (path !== '/' && activePath.indexOf(path) === 0)
                    ? 'active'
                    : ''
                }`}
              >
                <div className="flex flex-row items-center justify-start">
                  {Icon ? <Icon className="icon" /> : null}
                  <span className="navlabel">
                    <span style={{ whiteSpace: 'nowrap' }}>
                      {tk(item.label || 'missing label', item.label)}
                    </span>
                  </span>
                </div>
                {subs ? (
                  <ArrowRight01Icon className="subarrow w-[18px] h-[18px] mr-2" />
                ) : null}
              </NavElement>
              <NavElement
                to={path}
                className={`!flex lg:!hidden link ${
                  activePath === path ||
                  (path !== '/' && activePath.indexOf(path) === 0)
                    ? 'active'
                    : ''
                }`}
                onClick={() => toggleSidebar()}
              >
                <div className="flex flex-row items-center justify-start w-full ">
                  {Icon ? <Icon className="icon" /> : null}
                  <span className="navlabel">
                    <span style={{ whiteSpace: 'nowrap' }}>
                      {tk(item.label || 'missing label', item.label)}
                    </span>
                  </span>
                </div>
                {subs ? (
                  <ArrowRight01Icon className="subarrow w-[18px] h-[18px] mr-2" />
                ) : null}
              </NavElement>
              {subs ? (
                <MenuForRoutes
                  routes={listSubItems(item)}
                  parent={path}
                  subRoute
                />
              ) : null}
            </li>
          ) : null;
        })}
      </ul>
    );
  };

  return (
    <div
      className={`${appConfig?.id} sidebar sidebar-bg fixed overflow-x-hidden overflow-y-scroll hidescroll`}
      style={{
        minWidth: sideBarWidth,
        width: sideBarWidth,
        marginLeft: sideBarOpen ? 0 : sideBarWidth * -1,
      }}
    >
      <div className="container">
        <div className="nav">
          {appConfig?.routes?.length ? (
            <MenuForRoutes routes={appConfig.routes} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
