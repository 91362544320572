/* eslint-disable react-hooks/exhaustive-deps */
import { Suspense, useEffect, useState } from 'react';
import { RightSideBarSize, useSideBar } from '../hooks/useSideBar';
import ToolTip from './ToolTip';
import { CancelSquareIcon } from '@hugeicons/react';
import Loader from './Loader';

const RightSideBar = () => {
  const { rightSideBar, hideRightSideBar } = useSideBar();

  const [sideBarWidth, setSideBarWidth] = useState<number | undefined>();

  const updateSideBarWidth = () => {
    let rs = document.getElementById('rsbar');
    if (rs) {
      const rect = rs?.getBoundingClientRect() || null;
      setSideBarWidth(rect?.width);
    }
  };

  useEffect(() => {
    if (sideBarWidth === undefined) {
      window.addEventListener('resize', updateSideBarWidth);
    }
    updateSideBarWidth();
    return () => {
      window.removeEventListener('resize', updateSideBarWidth);
    };
  }, [rightSideBar]);

  return (
    <div className={`absolute top-0 left-0`}>
      <div
        className={`fixed top-0 left-0 w-screen h-[100dvh] bg-black opacity-60 z-[997] hover:cursor-pointer ${
          rightSideBar?.content ? 'flex' : 'hidden'
        }`}
        onClick={() => hideRightSideBar()}
      />
      <div
        id="rsbar"
        className={`fixed p-4 bg-white flex flex-col h-[100dvh] ease-out z-[998] duration-300 ${
          rightSideBar?.size === RightSideBarSize.full
            ? `w-[calc(100vw-68px)]`
            : rightSideBar?.size === RightSideBarSize.md
            ? `w-[calc(100vw-68px)] md:w-[65vw] lg:w-[50vw]`
            : 'w-[calc(100vw-68px)] md:w-[35vw] lg:w-[25vw] md:max-w-[400px]'
        }`}
        style={{
          right:
            rightSideBar?.content && sideBarWidth
              ? 0
              : sideBarWidth
              ? sideBarWidth * -1
              : '-100vw',
        }}
      >
        <div
          className="absolute top-4 right-10 flex justify-center items-center hover:cursor-pointer z-[990]"
          onClick={() => hideRightSideBar()}
        >
          <ToolTip tip="Sluiten">
            <CancelSquareIcon className="w-8 h-8 opacity-60 hover:opacity-100" />
          </ToolTip>
        </div>
        <div className="relative top-0 left-0 w-full h-[100dvh] overflow-x-hidden">
          <Suspense fallback={<Loader />}>
            {rightSideBar?.content || null}
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default RightSideBar;
