import { PopupSize, usePopup } from '../hooks/usePopup';
import ToolTip from './ToolTip';
import { CancelCircleIcon } from '@hugeicons/react';

const Popup = () => {
  const { popupOpen, popupContent, popupSettings, hidePop } = usePopup();

  return (
    <div className={`absolute top-0 left-0`}>
      <div
        className={`fixed top-0 left-0 w-screen h-[100dvh] bg-gray-900 opacity-75 z-[997] hover:cursor-pointer ${
          popupOpen ? 'flex' : 'hidden'
        }`}
        onClick={() => hidePop()}
      />
      <div
        className={`fixed rounded-md opacity-0 left-1/2 -translate-x-1/2 -translate-y-1/2 p-8 bg-white flex flex-col ease-in-out z-[998] ${
          popupSettings.size === PopupSize.full
            ? 'duration-300'
            : 'duration-500'
        } ${popupOpen ? 'top-1/2 opacity-100' : '-top-[100dvh]'} ${
          popupSettings.size === PopupSize.full
            ? `h-[96%] w-[96%]`
            : `w-auto h-auto max-w-[96%] max-h-[96%]`
        }`}
      >
        <div
          className="absolute top-2 right-2 h-[24px] w-[24px] rounded-md border-[1px] text-gray-800 flex justify-center items-center hover:cursor-pointer hover:bg-gray-200"
          onClick={() => hidePop()}
        >
          <ToolTip tip="Sluiten">
            <CancelCircleIcon className="w-4 h-4" />
          </ToolTip>
        </div>
        <div className="relative top-0 left-0 w-full h-full overflow-x-hidden overflow-y-scroll">
          {popupContent || null}
        </div>
      </div>
    </div>
  );
};

export default Popup;
