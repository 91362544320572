import { MouseEvent, useCallback } from 'react';
import './ToolTip.css';

interface ToolTipProps {
  children: any;
  tip: string;
  classNames?: string;
  onClick?: Function;
}

const ToolTip = ({ children, tip, classNames, onClick }: ToolTipProps) => {
  const positionParentChild = (child: HTMLElement, tip: HTMLElement) => {
    const screenWidth = window.innerWidth;
    const childBRect = child.getBoundingClientRect();
    const tipBRect = tip.getBoundingClientRect();
    let popToTop = childBRect.y > 100 ? true : false;
    let top = popToTop ? (tipBRect.height + 6) * -1 : tipBRect.height + 6;
    let left =
      (Math.round(tipBRect.width / 2) - Math.round(childBRect.width / 2)) * -1;
    let popToLeft = false;
    let popToRight = false;
    if (childBRect.x + Math.round(tipBRect.width / 2) > screenWidth - 30) {
      popToLeft = true;
      left = -1 * (tipBRect.width + 12);
      top =
        (Math.round(tipBRect.height / 2) - Math.round(childBRect.height / 2)) *
        -1;
    }
    if (childBRect.x - Math.round(tipBRect.width / 2) < 30) {
      popToRight = true;
      left = childBRect.width + 6;
      top =
        (Math.round(tipBRect.height / 2) - Math.round(childBRect.height / 2)) *
        -1;
    }
    tip.classList.remove('popbottom');
    tip.classList.remove('popleft');
    tip.classList.remove('popright');
    if (popToLeft) {
      tip.classList.add('popleft');
    } else {
      if (popToRight) {
        tip.classList.add('popright');
      } else {
        if (!popToTop) {
          tip.classList.add('popbottom');
        }
      }
    }
    tip.style.top = `${top}px`;
    tip.style.left = `${left}px`;
  };

  const showTip = (e: MouseEvent) => {
    const child = e.currentTarget.querySelector('.ttipchild') as HTMLElement;
    const tip = e.currentTarget.querySelector('.ttip') as HTMLElement;
    positionParentChild(child, tip);
    if (
      !tip.classList.contains('showttip') &&
      tip.classList.contains('hidettip')
    ) {
      tip.classList.remove('hidettip');
      tip.classList.add('showttip');
    }
  };

  const hideTip = (e: MouseEvent) => {
    const tip = e.currentTarget.querySelector('.ttip') as HTMLElement;
    if (
      !tip.classList.contains('hidettip') &&
      tip.classList.contains('showttip')
    ) {
      tip.classList.remove('showttip');
      tip.classList.add('hidettip');
    }
  };

  const tipRef = useCallback((elem: HTMLSpanElement) => {
    if (elem) {
      const child = elem.querySelector('.ttipchild') as HTMLElement;
      const tip = elem.querySelector('.ttip') as HTMLElement;
      positionParentChild(child, tip);
    }
  }, []);

  return (
    <span
      className={`relative inline-block ${classNames || ''}`}
      onMouseOver={(e: MouseEvent) => showTip(e)}
      onMouseOut={(e: MouseEvent) => hideTip(e)}
      onClick={(e: MouseEvent) => {
        e.preventDefault();
        if (onClick) {
          onClick();
        }
      }}
      ref={tipRef}
    >
      <span className="ttip hidden lg:block absolute whitespace-nowrap hidettip">
        {tip}
        <span className="ttippoint" />
      </span>
      <span className="absolute top-0 left-0 h-full w-full z-[9998]" />
      <span className="ttipchild">{children}</span>
    </span>
  );
};

export default ToolTip;
