import { ApiCall } from '../api/ApiCall';
import { MongoCall, MongoProcedure } from '../api/MongoProc';
import { DocTypes, LogDoc, LogOp } from '../types/Log';
import { StaffMemberRef } from '../types/StaffMember';
import { StaffTimeRegistration } from '../types/StaffTimeRegistration';
import { OneOfficeUser } from '../types/OneOfficeUser';

export const getStaffTimeRegistrationLatest = (user?: OneOfficeUser) => {
    return new ApiCall({
        mongoCall: new MongoCall({
            procedure: user?.staff?._id
                ? MongoProcedure.FINDONE
                : MongoProcedure.FIND,
            collection: 'lastDispatchHourRegistration',
            query: user
                ? {
                    'staff._id': user.staff?._id,
                }
                : {},
            sort: { type: 1, time: -1 },
        }),
    });
};

interface StaffTimeProps {
    staff: StaffMemberRef;
    office?: boolean;
    withBike?: boolean;
    activeWakkerBeller?: boolean;
    noTasks?: boolean;
}

export const storeStaffTime = async ({
    staff,
    office,
    withBike = false,
    activeWakkerBeller = false,
    noTasks = false,
}: StaffTimeProps) => {
    const staffLastRegistration = (
        await new ApiCall({
            mongoCall: new MongoCall({
                procedure: MongoProcedure.FINDONE,
                collection: 'lastDispatchHourRegistration',
                query: { 'staff._id': staff._id },
            }),
        }).execute()
    ).result;

    let body: StaffTimeRegistration = {
        time: Date.now(),
        type: staffLastRegistration?.type === 0 ? 1 : 0,
        timeType: 'aregistration',
        office:
            staffLastRegistration?.type === 0
                ? staffLastRegistration?.office
                : office || false,
        staff: {
            _id: staff._id,
            alias: staff.alias,
        },
    };
    if (!staffLastRegistration || staffLastRegistration.type === 1) {
        body = {
            ...body,
            withBike,
            activeWakkerBeller,
            noTasks,
        };
    }

    const staffTimeStore = new MongoCall({
        procedure: MongoProcedure.INSERTONE,
        collection: 'dispatchhours',
        body,
        log: new LogDoc(
            LogOp.insert,
            `Personeelslid ${staff.alias} ${body.type === 0 ? 'gestart' : 'gestopt'
            } met werken.`,
            DocTypes.stafftimes,
            ['new']
        ),
    });
    if ((await new ApiCall({ mongoCall: staffTimeStore }).execute()).result) {
        return true;
    } else {
        return false;
    }
};

export const updateNoTasksFlag = async (
    staff: StaffMemberRef,
    noTasks: boolean
) => {
    const staffLastRegistration = (
        await new ApiCall({
            mongoCall: new MongoCall({
                procedure: MongoProcedure.FINDONE,
                collection: 'lastDispatchHourRegistration',
                query: { 'staff._id': staff._id },
            }),
        }).execute()
    ).result;

    const staffTimeStore = new MongoCall({
        procedure: MongoProcedure.UPDATEONE,
        collection: 'dispatchhours',
        body: { ...staffLastRegistration, noTasks },
        log: new LogDoc(
            LogOp.update,
            `Personeelslid ${staff.alias} heeft taken ontvangen ${noTasks ? 'afgezet' : 'aangezet'
            }.`,
            DocTypes.stafftimes,
            [staffLastRegistration._id]
        ),
    });
    if ((await new ApiCall({ mongoCall: staffTimeStore }).execute()).result) {
        return true;
    } else {
        return false;
    }
};
