import Popper, { PopperActions } from '../Popper';
import { Action } from '../../types/Action';
import { useAuth } from '../../hooks/useAuth';
import { useStaffTime } from '../../hooks/useStaffTime';
import {
  getStaffTimeRegistrationLatest,
  storeStaffTime,
} from '../../viewActions/StaffTimeActions';
import { StaffTimeRegistration } from '../../types/StaffTimeRegistration';
import { useState } from 'react';
import Swal from 'sweetalert2';
import { OneOfficeUser, parseOneOfficeUser } from '../../types/OneOfficeUser';
import {
  Building05Icon,
  StopWatchIcon,
  Home05Icon,
  TaskDone01Icon,
  TaskRemove01Icon,
} from '@hugeicons/react';
import { addToast } from '../../utils/ToastUtil';

const StaffStartStopTimePop = () => {
  const { user } = useAuth();
  const { lastRegistration, setStatus, loadStatus } = useStaffTime();
  const [loading, setLoading] = useState<boolean>(false);
  const [userLastRegistration, setUserLastRegistrations] = useState<
    StaffTimeRegistration | undefined
  >();

  const loadPopTimeOptions = async () => {
    setLoading(true);
    const lastReg = (
      await getStaffTimeRegistrationLatest(user! as OneOfficeUser).execute()
    ).result;
    setLoading(false);
    if (lastReg && lastRegistration && lastReg.type !== lastRegistration.type) {
      setStatus(lastReg);
    }
    setUserLastRegistrations(lastReg);
  };

  const toggleTime = async (start?: boolean, office?: boolean) => {
    const lastReg = (
      await getStaffTimeRegistrationLatest(user! as OneOfficeUser).execute()
    ).result as StaffTimeRegistration;
    if (lastReg.type && !start) {
      return Swal.fire(
        'Probleem',
        'Je laatste registratie was een eindtijd. Je kan niet opnieuw beëindigen. Refresh de applicatie.',
        'error'
      );
    }
    if (lastReg.type === 0 && start) {
      return Swal.fire(
        'Probleem',
        'Je laatste registratie was een starttijd. Je kan niet opnieuw starten. Refresh de applicatie.',
        'error'
      );
    }

    const lastRegOffice = lastReg?.type ? office : lastReg.office;

    const storeTime = async (
      withBike?: boolean,
      activeWakkerBeller?: boolean
    ) => {
      if (!parseOneOfficeUser(user)?.staff) {
        return;
      }
      if (
        await storeStaffTime({
          staff: parseOneOfficeUser(user)?.staff!,
          office: lastRegOffice,
          withBike,
          activeWakkerBeller,
        })
      ) {
        addToast('Werktijd opgeslagen', 'success');
        loadStatus();
      } else {
        addToast('Probleem bij opslaan werktijd');
      }
    };

    const startRegistration = await Swal.fire({
      title: `Tijd ${start ? 'starten' : 'stoppen'}`,
      text: `Ben je zeker dat je de tijd wil ${
        start ? 'starten' : 'stoppen'
      } op plaats ${lastRegOffice ? 'bureau' : 'permanentie'}?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Ja',
      cancelButtonText: 'Annuleren',
    });
    if (startRegistration?.isConfirmed) {
      if (!start) {
        storeTime();
      } else {
        const withBike = (
          await Swal.fire({
            title: `Fiets`,
            text: 'Gelieve aan te geven of u met de fiets bent. Gelieve maar 1 keer ja te antwoorden per dag, ook al start je meerdere keren je tijd.',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Ja',
            cancelButtonText: 'Nee',
          })
        ).isConfirmed;
        const activeWakkerBeller = (
          await Swal.fire({
            title: `Wakkerbeller Systeem`,
            text: 'Bent u vanaf deze start verantwoordelijk om de wakkerbellers af te handelen?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Ja',
            cancelButtonText: 'Nee',
          })
        ).isConfirmed;
        storeTime(withBike, activeWakkerBeller);
      }
    }
  };

  return parseOneOfficeUser(user)?.staff?.timeRegistration ? (
    <Popper
      id="staffstartstop"
      icon={StopWatchIcon}
      classNames="mr-4"
      titleClasses={`circular ${
        lastRegistration?.type === 0
          ? lastRegistration?.noTasks
            ? 'bg-orange-600'
            : 'bg-teal-500'
          : 'bg-red-500'
      }`}
      onPopClick={() => loadPopTimeOptions()}
    >
      <PopperActions
        loading={loading}
        actions={
          !loading && userLastRegistration
            ? userLastRegistration.type === 0
              ? [
                  new Action({
                    actionEvent: () => toggleTime(),
                    label: `Taken ${
                      userLastRegistration.noTasks ? 'activeren' : 'deactiveren'
                    }`,
                    classnames: `popper-action ${
                      userLastRegistration.noTasks ? 'search' : 'delete'
                    }`,
                    icon: userLastRegistration.noTasks
                      ? TaskDone01Icon
                      : TaskRemove01Icon,
                  }),
                  new Action({
                    actionEvent: () => toggleTime(),
                    label: `Stop werktijd ${
                      userLastRegistration.office ? 'bureau' : 'permanentie'
                    }`,
                    classnames: `popper-action delete`,
                    icon: userLastRegistration.office
                      ? Building05Icon
                      : Home05Icon,
                  }),
                ]
              : [
                  new Action({
                    actionEvent: () => toggleTime(true, true),
                    label: `Start werktijd bureau`,
                    classnames: `popper-action search`,
                    icon: Building05Icon,
                  }),
                  new Action({
                    actionEvent: () => toggleTime(true),
                    label: `Start werktijd thuis`,
                    classnames: `popper-action search`,
                    icon: Home05Icon,
                  }),
                ]
            : []
        }
      />
    </Popper>
  ) : null;
};

export default StaffStartStopTimePop;
