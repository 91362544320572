/* eslint-disable react-hooks/exhaustive-deps */
import React, { Children, FC, ReactNode, useEffect, useState } from 'react';
import { getRandomInt } from '../utils/NumberUtil';
import { ActionList } from '../types/Action';
import { IconType } from '../types/Icon';
import ActionButton from './ActionButton';
import { BeatLoader } from 'react-spinners';
import IconButton from './IconButton';

type PopLeftTop = {
  left: number;
  top: number;
};

interface PopperProps {
  id?: string;
  classNames?: string;
  title?: string;
  titleClasses?: string;
  icon?: IconType;
  iconClasses?: string;
  hoverPop?: boolean;
  disableHover?: boolean;
  children: ReactNode;
  onPopClick?: Function;
}

interface PopperActionsProps {
  loading?: boolean;
  actions: ActionList;
}

interface IPopperElementOptions {
  id: string;
  classNames?: string;
  title?: string;
  titleClasses?: string;
  icon?: IconType;
  iconClasses?: string;
  hoverPop?: boolean;
  disableHover?: boolean;
  onClick?: Function;
  actions: ActionList;
}

export class PopperElement {
  id: string;
  classNames?: string;
  title?: string;
  titleClasses?: string;
  icon?: IconType;
  iconClasses?: string;
  hoverPop: boolean;
  disableHover?: boolean;
  onClick?: Function;
  actions: ActionList;

  constructor(options: IPopperElementOptions) {
    if (!options?.id) {
      throw new Error('id is required for PopperElement');
    }
    if (!options?.actions?.length) {
      throw new Error('at least 1 action is required for PopperElement');
    }
    this.id = options.id;
    this.classNames = options.classNames;
    this.title = options.title;
    this.titleClasses = options.titleClasses;
    this.icon = options.icon;
    this.iconClasses = options.iconClasses;
    this.hoverPop = options.hoverPop || false;
    this.disableHover = options.disableHover;
    this.onClick = options.onClick;
    this.actions = options.actions;
  }
}

export const PopperActions: FC<PopperActionsProps> = ({ loading, actions }) => {
  return (
    <div className="popper-actions">
      {loading ? (
        <div className="min-w-[100px] p-2 rounded-md bg-gray-100">
          <BeatLoader color="black" size={8} />
        </div>
      ) : null}
      {!loading && actions?.length
        ? actions?.map((action, a) => (
            <ActionButton
              action={{
                ...action,
                classnames: `${action.classnames} popper-action transparant`,
              }}
              key={a}
              fill={true}
            />
          ))
        : null}
    </div>
  );
};

const Popper: FC<PopperProps> = ({
  id = getRandomInt(111111, 999999).toString(),
  classNames,
  title,
  titleClasses,
  icon,
  iconClasses,
  hoverPop = false,
  disableHover,
  children,
  onPopClick,
}) => {
  const [popInit, setPopInit] = useState<DOMRect | null>(null);
  const [popActive, setPopActive] = useState<string | null>(null);
  const [popLeftTop, setPopLeftTop] = useState<PopLeftTop>({
    left: 0,
    top: 0,
  });
  const [popChildren, setPopChildren] = useState<any>();

  const setPopPlacement = (defaultPopInit: DOMRect | null) => {
    const popper = document.getElementById(id);
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    let popToLeft = false;
    let popDown = true;
    let popperTop = 0;
    let popperHeight = 0;
    let popperWidth = 0;
    let popperLeft = 0;
    let popWidth = 0;
    let popHeight = 0;
    if (popper) {
      popperTop = popper.getBoundingClientRect().y;
      popperLeft = popper.getBoundingClientRect().x;
      popperHeight = popper.getBoundingClientRect().height;
      popperWidth = popper.getBoundingClientRect().width;
      popToLeft = windowWidth - popperLeft > windowWidth / 2 ? false : true;
    }
    const popDim = defaultPopInit || popInit;
    if (popDim) {
      popHeight = popDim.height;
      popWidth = popDim.width;
      popDown =
        windowHeight - popperTop - popperHeight - popHeight < 24 ? false : true;
    }

    setPopLeftTop({
      left: popToLeft
        ? popperWidth > popWidth + 4
          ? popperWidth - popWidth - 4
          : -1 * (popWidth - popperWidth)
        : -4,
      top: popDown ? popperHeight - 4 : -1 * (popHeight - 4),
    });
  };

  const popClicked = () => {
    setPopActive(popActive ? null : id);
    if (onPopClick) {
      onPopClick(popActive ? false : true);
    }
  };

  const updatePopPlacement = () => {
    let init = null;
    let newKeys: { loading?: any; actions?: number; childItems?: number } = {};
    let childItems = 0;
    if (React.isValidElement(children)) {
      Children!.map(children, (child) => {
        childItems++;
        if (
          child.props?.loading !== undefined ||
          child.props?.actions?.length
        ) {
          newKeys = {
            loading: child.props?.loading,
            actions: child.props?.actions?.length,
          };
        }
      });
    }
    newKeys.childItems = childItems;
    let pop = document.getElementById(`${id}-pop`);
    if (pop) {
      pop.style.display = 'block';
    }
    const rect = pop?.getBoundingClientRect() || null;
    if (
      (!popInit && rect?.width && rect?.height) ||
      JSON.stringify(newKeys) !== JSON.stringify(popChildren)
    ) {
      init = rect;
      setPopInit(rect);
      if (newKeys) {
        setPopChildren(newKeys);
      }
    } else {
      if (
        rect?.width &&
        rect?.height &&
        JSON.stringify(rect) !== JSON.stringify(popInit)
      ) {
        setPopInit(rect);
        init = rect;
      }
    }
    if (pop) {
      pop.style.display = 'none';
    }
    if (init) {
      setPopPlacement(init);
    }
  };

  useEffect(() => {
    if (!popInit) {
      window.addEventListener('resize', updatePopPlacement);
    }
    updatePopPlacement();
    return () => {
      window.removeEventListener('resize', updatePopPlacement);
    };
  }, [children]);

  return (
    <div
      id={id}
      className={`relative hover:cursor-pointer group ${classNames || ''}`}
      onClick={() => (hoverPop ? null : popClicked())}
      onMouseEnter={() => (hoverPop ? updatePopPlacement() : null)}
    >
      {!hoverPop && popActive === id ? (
        <div
          className="w-[100vw] h-[100dvh] fixed top-0 left-0 bg-black opacity-50 z-[990]"
          onClick={() => {
            setPopActive(id);
          }}
        />
      ) : null}
      <div
        className={`flex flex-row items-center h-[36px] px-4 rounded-full border-[1px] border-menu-400 text-white text-sm scale-100 transition-all [&.rounded]:px-2 [&.circular]:w-[36px] [&.circular]:justify-center [&.circular]:px-0 ${
          titleClasses || ''
        } ${hoverPop ? `group-hover:text-bold` : ''} ${
          !disableHover ? 'hover:scale-110' : ''
        }`}
      >
        {icon ? <IconButton icon={icon} className={iconClasses} /> : null}{' '}
        {title ? (
          <span className={`${icon ? 'ml-1' : ''}`}>{title}</span>
        ) : null}
      </div>
      <div
        id={`${id}-pop`}
        className={`absolute bg-white p-0 rounded-lg flex-col items-start shadow-lg z-[991] overflow-hidden [&.btn]:m-0 [&>.btn]:w-full [&>.btn.pop-btn]:bg-transparent ${
          !popInit ? 'flex' : 'hidden'
        } ${popInit && hoverPop ? `group-hover:!block` : ''} ${
          popInit && popActive === id ? '!flex' : ''
        }`}
        style={{
          top: popLeftTop.top,
          left: popLeftTop.left,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Popper;
