/* eslint-disable react-hooks/exhaustive-deps */
import {
  FC,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Order } from '../types/Order';
import { getRandomInt } from '../utils/NumberUtil';
import { APPID, getAppFront } from '../types/AppConfig';
import {
  removeFromTempStorage,
  retrieveFromTempStorage,
  saveToTempStorage,
} from '../utils/StorageUtil';

export type BasketOrder = Order & { basketItemID?: number };

type AddBasketFunc = (order: Order[]) => void;
type RemoveBasketFunc = (basketItemID: number) => void;
type ClearBasketFunc = () => void;

interface ConnectBasketContextType {
  basket: BasketOrder[];
  addBasketItems: AddBasketFunc;
  removeBasketItem: RemoveBasketFunc;
  clearBasket: ClearBasketFunc;
}

interface ConnectBasketProviderProps {
  children: any;
}

const ConnectBasketContext = createContext<ConnectBasketContextType>(
  {} as ConnectBasketContextType
);

const basketStorageKey = `cbasket`;

export const ConnectBasketProvider: FC<ConnectBasketProviderProps> = ({
  children,
}) => {
  const [basket, setBasket] = useState<BasketOrder[]>([]);

  const addBasketItems = (orders: Order[]) => {
    orders = orders.map((order) => {
      return {
        ...order,
        basketItemID: getRandomInt(11111111, 99999999),
      } as BasketOrder;
    });
    const updatedBasket = basket.concat(orders as BasketOrder[]);
    setBasket(updatedBasket);
    saveToTempStorage(basketStorageKey, updatedBasket);
  };

  const removeBasketItem = (basketItemID: number) => {
    const updatedBasket = basket.filter(
      (bItem) => bItem.basketItemID !== basketItemID
    );
    setBasket(updatedBasket);
    saveToTempStorage(basketStorageKey, updatedBasket);
  };

  const clearBasket = () => {
    setBasket([]);
    removeFromTempStorage(basketStorageKey);
  };

  useEffect(() => {
    if (getAppFront() === APPID.connect) {
      const currentBasket: BasketOrder[] | undefined = retrieveFromTempStorage(
        basketStorageKey
      ) as BasketOrder[];
      if (currentBasket?.length) {
        setBasket(currentBasket);
        saveToTempStorage(basketStorageKey, currentBasket);
      }
    }
  }, []);

  const value = useMemo(
    () => ({
      basket,
      addBasketItems,
      removeBasketItem,
      clearBasket,
    }),
    [basket]
  );
  return (
    <ConnectBasketContext.Provider value={value}>
      {children}
    </ConnectBasketContext.Provider>
  );
};

export const useConnectBasket = () => {
  return useContext(ConnectBasketContext);
};
