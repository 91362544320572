import { Routes, Route, Navigate } from 'react-router-dom';
import { ProtectedRoute } from './ProtectedRoute';
import { SideBarProvider } from './hooks/useSideBar';
import { PopupProvider } from './hooks/usePopup';
import { SearchProvider } from './hooks/useSearch';
import { RecentItemProvider } from './hooks/useRecentItems';
import PageLayout from './components/PageLayout';
import { RouteRoleCheck } from './RouteRoleCheck';
import { FC, lazy, Suspense } from 'react';
import { StaffTimeProvider } from './hooks/useStaffTime';
import apps from './apps';
import { getAppConfigForID, getAppFront } from './types/AppConfig';
import { AppRoute } from './types/AppRoute';
import AppLoader from './components/AppLoader';
import SignOut from './views/SignOut';
import { ConnectBasketProvider } from './hooks/useConnectBasket';

const SignIn = lazy(() => import('./views/SignIn'));
const SignUp = lazy(() => import('./views/SignUp'));
const PasswordReset = lazy(() => import('./views/PasswordReset'));

const Router = () => {
  const appConfig = getAppConfigForID(apps, getAppFront());
  const indexRoute: AppRoute | undefined = appConfig?.routes?.length
    ? appConfig.routes.find((route) => route.index)
    : undefined;
  const indexPath = indexRoute?.path
    ? Array.isArray(indexRoute.path)
      ? indexRoute.path[0]
      : indexRoute.path
    : '/signout';
  return (
    <Suspense fallback={<AppLoader />}>
      <Routes>
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/passwordreset" element={<PasswordReset />} />
        <Route path="/signout" element={<SignOut />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <SideBarProvider>
                <PopupProvider>
                  <SearchProvider>
                    <RecentItemProvider>
                      <StaffTimeProvider>
                        <ConnectBasketProvider>
                          <PageLayout />
                        </ConnectBasketProvider>
                      </StaffTimeProvider>
                    </RecentItemProvider>
                  </SearchProvider>
                </PopupProvider>
              </SideBarProvider>
            </ProtectedRoute>
          }
        >
          {appConfig?.routes?.length
            ? appConfig?.routes?.map((rItem: AppRoute) => {
                let { pathForDetail, path } = rItem;
                const rPath = Array.isArray(path) ? path : [path];
                const paths: string[] = pathForDetail
                  ? rPath.concat([`${path}/:id`])
                  : rPath;
                const RouteElement: FC | null = rItem.element || null;
                return RouteElement
                  ? paths.map((path, p) => (
                      <Route
                        key={p}
                        index={rItem.index || false}
                        element={
                          <RouteRoleCheck role={rItem.role}>
                            <RouteElement />
                          </RouteRoleCheck>
                        }
                        path={path}
                      />
                    ))
                  : null;
              })
            : null}
          <Route path="*" element={<Navigate to={indexPath} replace />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default Router;
