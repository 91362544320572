const replaceOnlyAlpha = (s: string) => {
    return s.replace(/[0-9]/g, '');
}

const replaceSpecial = (s: string) => {
    s = s.replace(/[ÆÁÀÂÄǍĂĀÃÅǺĄÆǼǢ]/g, 'A');
    s = s.replace(/[Ɓ]/g, 'B');
    s = s.replace(/[ĆĊĈČÇ]/g, 'C');
    s = s.replace(/[ĎḌĐƊÐ]/g, 'D');
    s = s.replace(/[ÉÈĖÊËĚĔĒĘẸƎƏƐ]/g, 'E');
    s = s.replace(/[ĠĜǦĞĢƔ]/g, 'G');
    s = s.replace(/[ĤḤĦ]/g, 'H');
    s = s.replace(/[IÍÌİÎÏǏĬĪĨĮỊĲ]/g, 'I');
    s = s.replace(/[ĴĴ]/g, 'J');
    s = s.replace(/[ĶƘ]/g, 'K');
    s = s.replace(/[ĹĻŁĽĿ]/g, 'L');
    s = s.replace(/[NŃN̈ŇÑŅŊ]/g, 'N');
    s = s.replace(/[ÓÒÔÖǑŎŌÕŐỌØǾƠŒĥ]/g, 'O');
    s = s.replace(/[ŔŘŖ]/g, 'R');
    s = s.replace(/[ŚŜŠŞȘṢẞ]/g, 'S');
    s = s.replace(/[ŤŢṬŦ]/g, 'T');
    s = s.replace(/[ÚÙÛÜǓŬŪŨŰŮŲỤƯ]/g, 'U');
    s = s.replace(/[ẂẀŴẄ]/g, 'W');
    s = s.replace(/[ÝỲŶŸȲỸƳ]/g, 'Y');
    s = s.replace(/[ŹŻŽẒ]/g, 'Z');
    s = s.replace(/[ŚŜŠŞȘṢ]/g, 'J');
    s = s.replace(/[^0-9A-Z]/g, '');
    return s;
}

export const searchSafe = (unsafe: string, numbers?: boolean): string => {
    unsafe = unsafe ? unsafe : '';
    unsafe = unsafe.toUpperCase();
    if (!numbers) {
        unsafe = replaceOnlyAlpha(unsafe);
    }
    return replaceSpecial(unsafe);
}

export const truncate = (text: string, maxLength: number): string => {
    if (text?.length < maxLength) return text;
    return `${text.substring(0, maxLength)}...`;
}

export const stringToSearchArray = (unsafe: string, numbers?: boolean, forceSplitter?: string): string[] => {
    unsafe = unsafe ? unsafe : '';
    unsafe = unsafe.toUpperCase();
    let stringArr = [];
    if (forceSplitter) {
        stringArr = unsafe.split(forceSplitter);
        let fullConcatArr: string[] = [];
        for (var a = 0; a < stringArr.length; a++) {
            var exSplit = stringArr[a].split(' ');
            fullConcatArr = fullConcatArr.concat(exSplit);
        }
        stringArr = fullConcatArr;
    } else {
        stringArr = unsafe.split(' ');
    }
    if (stringArr && stringArr.length) {
        var extraArr = [];
        var fullTextItem = '';
        for (var s = 0; s < stringArr.length; s++) {
            if (!numbers) {
                stringArr[s] = replaceOnlyAlpha(stringArr[s]);
            }
            stringArr[s] = replaceSpecial(stringArr[s]);
            fullTextItem += stringArr[s];
            if (s > 0) {
                extraArr.push(fullTextItem);
            }
        }
        return stringArr.concat(extraArr);
    } else {
        return [];
    }
}