import { FC, MouseEvent } from 'react';
import { Action, ActionType } from '../types/Action';
import {
  ArrowRight04Icon,
  Delete01Icon,
  Edit01Icon,
  FilterIcon,
  PlusSignIcon,
  PrinterIcon,
  Search02Icon,
} from '@hugeicons/react';
import { useGorseleTranslation } from '../hooks/useGorseleTranslation';

interface ActionButtonProps {
  action: Action;
  iconOnly?: boolean;
  iconOnlyMobile?: boolean;
  onClick?: Function | null;
  [key: string]: any;
}

interface ActionIconProps {
  action: Action;
  [key: string]: any;
}

const ActionIcon: FC<ActionIconProps> = ({ action, ...rest }) => {
  const getIcon = () => {
    switch (action.type) {
      case ActionType.filter:
        return <FilterIcon {...rest} />;
      case ActionType.search:
        return <Search02Icon {...rest} />;
      case ActionType.new:
        return <PlusSignIcon {...rest} />;
      case ActionType.delete:
        return <Delete01Icon {...rest} />;
      case ActionType.edit:
        return <Edit01Icon {...rest} />;
      case ActionType.browse:
        return <ArrowRight04Icon {...rest} />;
      case ActionType.print:
        return <PrinterIcon {...rest} />;
      default:
        const DefIcon = action.icon || null;
        return DefIcon ? <DefIcon {...rest} /> : null;
    }
  };

  return <div>{getIcon()}</div>;
};

const ActionButton: FC<ActionButtonProps> = ({
  action,
  iconOnly,
  iconOnlyMobile,
  onClick,
  fill,
  ...rest
}) => {
  const { tk } = useGorseleTranslation();
  const hasIcon: boolean =
    action.icon || (action.type && action.type !== ActionType.custom)
      ? true
      : false;

  const getLabel = () => {
    if (action?.label?.length) return action.label;
    switch (action.type) {
      case ActionType.filter:
        return tk('app.action.filter', 'Filter');
      case ActionType.search:
        return tk('app.action.search', 'Zoeken');
      case ActionType.new:
        return tk('app.action.new', 'Nieuw');
      case ActionType.delete:
        return tk('app.action.delete', 'Verwijder');
      case ActionType.edit:
        return tk('app.action.edit', 'Bewerk');
      case ActionType.browse:
        return tk('app.action.browse', 'Bladeren');
      case ActionType.print:
        return tk('app.action.print', 'Print');
      default:
        return action.label || 'Label';
    }
  };

  return (
    <button
      className={`btn ${fill ? '!rounded-none' : ''} ${
        action.type.toLowerCase() || ''
      } ${action.classnames || ''}`}
      onClick={(e: MouseEvent<HTMLElement>) => {
        e.preventDefault();
        return onClick
          ? onClick()
          : action.actionEvent
          ? action.actionEvent()
          : () => {};
      }}
      disabled={
        action.disabled
          ? typeof action.disabled === 'boolean'
            ? action.disabled
            : false
          : false
      }
      {...rest}
    >
      <ActionIcon action={action} />
      <span
        className={`whitespace-nowrap ${hasIcon ? 'ml-1' : ''} ${
          iconOnlyMobile && hasIcon ? 'hidden lg:inline-block' : ''
        }`}
      >
        {!iconOnly ? getLabel() : null}
      </span>
    </button>
  );
};

export default ActionButton;
