import Popper from '../Popper';
import { getInitials } from '../../types/AppUser';
import { useAuth } from '../../hooks/useAuth';
import { useGorseleTranslation } from '../../hooks/useGorseleTranslation';

const UserPop = () => {
  const { user, logout } = useAuth();
  const { tk } = useGorseleTranslation();
  return user ? (
    <Popper
      id="useravatar"
      title={getInitials(user)}
      titleClasses="circular !border-none bg-purple-500 text-xs"
    >
      <div className="p-2 flex flex-col items-center">
        <span className="name whitespace-nowrap text-xs">
          {user?.fullName || 'Onbekend'}
        </span>
        <div className="mt-2 w-full">
          <button
            className="btn danger w-full whitespace-nowrap"
            onClick={() => logout()}
          >
            {tk('app.action.signout', 'Afmelden')}
          </button>
        </div>
      </div>
    </Popper>
  ) : null;
};

export default UserPop;
