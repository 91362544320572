import { FC } from 'react';
import { IconType } from '../types/Icon';

interface IconButtonProps {
  icon: IconType;
  [key: string]: any;
}

const IconButton: FC<IconButtonProps> = ({ icon, className, ...rest }) => {
  const RenderIcon = icon;
  return (
    <RenderIcon
      className={`w-[16px] h-[16px] hover:cursor-pointer ${className}`}
      {...rest}
    />
  );
};

export default IconButton;
