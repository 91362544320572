import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './hooks/useAuth';
import { isValidToken } from './token';

interface ProtectedRouteProps {
  children: ReactNode;
  urlPrefix?: string;
}

export const ProtectedRoute: FC<ProtectedRouteProps> = ({
  children,
  urlPrefix,
}): any => {
  const { jwt } = useAuth();
  if (!isValidToken(jwt)) {
    // user is not authenticated
    return <Navigate to={`${urlPrefix || ''}/signin`} />;
  }
  return children;
};
