import { NestedArrayKeyOf } from "../utils/TypeUtil";
import { AppUser } from "./AppUser";
import { ObjectId } from "./MongoDB";

type OneOfficeUserStaffDetail = {
    _id: string;
    firstName: string;
    lastName: string;
    alias: string;
    timeRegistration?: boolean;
}

export type OneOfficeUserRef = {
    _id: string,
    fullName: string
}

export const OneOfficeUserRoles = {
    admin: 'Admin',
    dispatch: 'Dispatcher',
    management: 'Management',
    accountant: 'Boekhouding',
    visitor: 'Gast',
} as const;

type OneOfficeUserRole = typeof OneOfficeUserRoles;
export type OneOfficeUserRolePropType = keyof OneOfficeUserRole;
export type OneOfficeUserRoleType = {
    [key in OneOfficeUserRolePropType]?: string;
};

export type OneOfficeUser = {
    _id?: ObjectId;
    active?: boolean;
    firstName: string;
    lastName: string;
    fullName?: string;
    username: string;
    email: string;
    role: OneOfficeUserRolePropType;
    myPortalDevice?: number;
    myPortalCustomPassword?: string;
    staff?: OneOfficeUserStaffDetail;
    staff_id?: string;
    password?: string;
    password2?: string;
}

export const OneOfficeUserProperty = (key: NestedArrayKeyOf<OneOfficeUser>) => {
    return key;
};

export const parseOneOfficeUser = (user?: AppUser): OneOfficeUser | undefined => {
    if (!user) return undefined;
    return { ...user } as OneOfficeUser;
}