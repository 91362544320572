/* eslint-disable react-hooks/exhaustive-deps */
import * as rdd from 'react-device-detect';
import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

export enum RightSideBarSize {
  xs = 'xs',
  md = 'md',
  full = 'full',
}

type UpdateSideBarWidthFunction = (width: number) => void;
type ShowRightSideBarFunction = (
  content: ReactNode,
  size?: RightSideBarSize | undefined
) => void;

type RightSideBarType = {
  content: ReactNode | null;
  size: RightSideBarSize;
};

interface SideBarContextType {
  isMobile: boolean;
  sideBarOpen: boolean;
  sideBarWidth: number;
  rightSideBar?: RightSideBarType;
  updateSideBarWidth: UpdateSideBarWidthFunction;
  toggleSidebar: Function;
  showRightSideBar: ShowRightSideBarFunction;
  hideRightSideBar: Function;
}

interface SideBarProviderProps {
  children: any;
}

const normalWidth = 228;
const mobileMaxWidth = 567;
const defaultShowMinWidth = 1280;

const SideBarContext = createContext<SideBarContextType>(
  {} as SideBarContextType
);

export const SideBarProvider: FC<SideBarProviderProps> = ({ children }) => {
  const [isMobile, setIsMobile] = useState<boolean>(
    rdd.isMobile || window.innerWidth < mobileMaxWidth
  );
  const [sideBarWidth, setSideBarWidth] = useState<number>(
    window.innerWidth < mobileMaxWidth ? window.innerWidth : normalWidth
  );
  const [sideBarOpen, setSideBarOpen] = useState<boolean>(
    rdd.isMobile || window.innerWidth < defaultShowMinWidth ? false : true
  );
  const [rightSideBar, setRightSideBar] = useState<RightSideBarType>();

  // call this function when you want the sidebar to open/close
  const updateSideBarWidth = (width: number): void => {
    setSideBarWidth(width);
  };

  // call this function when you want the sidebar to open/close
  const toggleSidebar = () => {
    setSideBarOpen(!sideBarOpen);
  };

  const showRightSideBar = (
    content: ReactNode,
    size?: RightSideBarSize
  ): void => {
    setRightSideBar({
      content,
      size: size || RightSideBarSize.xs,
    });
  };

  const hideRightSideBar = () => {
    setRightSideBar(undefined);
  };

  const updateScreenSize = () => {
    setIsMobile(rdd.isMobile || window.innerWidth < mobileMaxWidth);
    setSideBarWidth(
      window.innerWidth < mobileMaxWidth ? window.innerWidth : normalWidth
    );
    setSideBarOpen(
      rdd.isMobile || window.innerWidth < defaultShowMinWidth ? false : true
    );
  };

  useEffect(() => {
    updateScreenSize();
    window.addEventListener('resize', updateScreenSize);
    return () => {
      window.removeEventListener('resize', updateScreenSize);
    };
  }, []);

  const value = useMemo(
    () => ({
      isMobile,
      sideBarOpen,
      sideBarWidth,
      rightSideBar,
      updateSideBarWidth,
      toggleSidebar,
      showRightSideBar,
      hideRightSideBar,
    }),
    [isMobile, sideBarOpen, sideBarWidth, rightSideBar]
  );
  return (
    <SideBarContext.Provider value={value}>{children}</SideBarContext.Provider>
  );
};

export const useSideBar = () => {
  return useContext(SideBarContext);
};
