import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import AppHeader from './AppHeader';
import RightSideBar from './RightSideBar';
import Popup from './Popup';

const PageLayout = () => {
  return (
    <div className="bottom-0 left-0 right-0 top-0 absolute overflow-hidden flex flex-col w-screen h-[100dvh]">
      <Popup />
      <RightSideBar />
      <AppHeader />
      <div className="flex flex-1 flex-row" aria-hidden="false">
        <Sidebar />
        <div className="flex flex-1 flex-col transition-all duration-500">
          <div className="w-full min-h-full">
            <div className="h-[calc(100dvh-60px)] w-full overflow-x-hidden overflow-y-auto">
              {<Outlet />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageLayout;
