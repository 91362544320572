import { lazy } from 'react';
import { AppConfigType, APPID } from '../types/AppConfig';
import { AppRoute } from '../types/AppRoute';
import {
    AddressBookIcon,
    Money03Icon,
    Task01Icon,
    UserGroupIcon,
    UserIcon,
} from '@hugeicons/react';
import nl from './connect/locales/nl.json';
import en from './connect/locales/en.json';


const ConnectOrders = lazy(
    () => import('../views/connect/orders/ConnectOrders')
);
const ConnectOrdersDetailStatic = lazy(
    () => import('../views/connect/orders/ConnectOrdersDetailStatic'));
const ConnectAddresses = lazy(
    () => import('../views/connect/addresses/ConnectAddresses')
);
const ConnectPeople = lazy(
    () => import('../views/connect/people/ConnectPeople')
);
const ConnectBudgets = lazy(
    () => import('../views/connect/budgets/ConnectBudgets')
);
const ConnectUsers = lazy(() => import('../views/connect/users/ConnectUsers'));
const ConnectBasket = lazy(() => import('../views/connect/basket/ConnectBasket'));

const app: AppConfigType = {
    id: APPID.connect,
    name: 'Gorselé Connect',
    description:
        'Gorselé Connect is a public order application for Gorselé customers',
    version: 2,
    locales: {
        nl: {
            translation: nl,
            label: 'Nederlands',
        },
        /*en: {
             translation: en,
             label: 'English',
         },*/
    },
    routes: [
        new AppRoute({
            path: ['/', '/orders'],
            label: 'navigation.orders',
            element: ConnectOrders,
            nav: true,
            icon: Task01Icon,
            index: true,
        }),
        new AppRoute({
            path: '/orders/detail',
            pathForDetail: true,
            element: ConnectOrdersDetailStatic,
        }),
        new AppRoute({
            path: '/addresses',
            label: 'navigation.addresses',
            element: ConnectAddresses,
            nav: true,
            icon: AddressBookIcon,
        }),
        new AppRoute({
            path: '/people',
            label: 'navigation.people',
            element: ConnectPeople,
            nav: true,
            icon: UserGroupIcon,
        }),
        new AppRoute({
            path: '/budgets',
            label: 'navigation.budgets',
            element: ConnectBudgets,
            nav: true,
            icon: Money03Icon,
        }),
        new AppRoute({
            path: '/users',
            label: 'navigation.users',
            element: ConnectUsers,
            nav: true,
            icon: UserIcon,
        }),
        new AppRoute({
            path: '/basket',
            element: ConnectBasket,
        }),
    ],
};

export default app;
