import { add } from "date-fns";
import { getAppFront } from "../types/AppConfig";

type TempStorageValue = {
    time: number;
    data: any;
}

const getTempKey = (key?: string) => {
    return `gorsele-${getAppFront()}-temp-${key || ''}`;
}

export const saveToTempStorage = (key: string, value: any) => {
    localStorage.setItem(getTempKey(key), JSON.stringify({
        time: Date.now(),
        data: value
    } as TempStorageValue))
}

export const retrieveFromTempStorage = (key: string): any => {
    try {
        const tmp = localStorage.getItem(getTempKey(key));
        if (!tmp) return;
        const parsed = JSON.parse(tmp) as TempStorageValue;
        if (parsed?.data) {
            if (parsed?.time > add(Date.now(), { hours: -12 }).getTime()) {
                return parsed.data;
            }
            else {
                localStorage.removeItem(getTempKey(key));
            }
        }
        return undefined;
    }
    catch {
        return undefined;
    }
}

export const removeFromTempStorage = (key: string) => {
    localStorage.removeItem(getTempKey(key));
}

export const clearTempStorage = (): void => {
    Object.keys(localStorage).forEach((key) => {
        if (key.startsWith(getTempKey())) {
            localStorage.removeItem(key);
        }
    });
};