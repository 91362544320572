import { ReactNode } from 'react';
import { APPID, getAppFront } from '../types/AppConfig';

type AppSpecificProps = {
  appid: APPID;
  children: ReactNode;
  [key: string]: any;
};

const AppSpecific = ({ appid, children }: AppSpecificProps) => {
  return getAppFront() === appid ? <>{children}</> : null;
};

export default AppSpecific;
