import { lazy } from 'react';
import { AppConfigType, APPID } from '../types/AppConfig';
import { AppRoute } from '../types/AppRoute';
import {
    Task01Icon,
    TaskAdd01Icon,
    TaskDone01Icon,
    TaxiIcon,
    UserGroupIcon,
} from '@hugeicons/react';

const OneOrders = lazy(() => import('../views/one/orders/OneOrders'));
const PlannedOneOrders = lazy(
    () => import('../views/one/orders/planned/PlannedOneOrders')
);
const ExecutedOneOrders = lazy(
    () => import('../views/one/orders/executed/ExecutedOneOrders')
);
const OneStaff = lazy(() => import('../views/one/staff/OneStaff'));
const OneVehicles = lazy(() => import('../views/one/vehicles/OneVehicles'));

const app: AppConfigType = {
    id: APPID.one,
    name: 'Gorselé One',
    description: 'Gorselé One is a public planning app for contractors',
    version: 2,
    signInEmail: true,
    routes: [
        new AppRoute({
            path: '/',
            label: 'Beschikbare orders',
            element: OneOrders,
            nav: true,
            icon: TaskAdd01Icon,
            index: true,
        }),
        new AppRoute({
            path: '/plannedorders',
            label: 'Geplande orders',
            element: PlannedOneOrders,
            nav: true,
            icon: Task01Icon,
        }),
        new AppRoute({
            path: '/executedorders',
            label: 'Uitgevoerde orders',
            element: ExecutedOneOrders,
            nav: true,
            icon: TaskDone01Icon,
        }),
        new AppRoute({
            path: '/staff',
            label: 'Personeel',
            element: OneStaff,
            nav: true,
            icon: UserGroupIcon,
        }),
        new AppRoute({
            path: '/vehicles',
            label: 'Voertuigen',
            element: OneVehicles,
            nav: true,
            icon: TaxiIcon,
        }),
    ],
};

export default app;
